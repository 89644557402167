<template>
  <div class="relative z-200 w-100w overflow-x-hidden relative">
    <v-app-bar
      ref="appBar"
      fixed
      flat
      class="v-app noevent"
      :class="{ 'fade-out2': !up }"
      :color="color"
    >
      <div class="w-100 h-100">
        <div class="text-center mt-4 disno-menu relative-center inline-block">
          <v-row class="menu_row">
            <v-col
              cols="3"
              v-for="m in $route.path == '/' ? menus : menus2"
              :key="m.url"
              class="f-09 bold flex-center mx-0 px-0 my-1 event"
              :style="
                m.name == 'お問合せ' ? '' : 'border-right:1px solid #aaa;'
              "
            >
              <router-link
                :to="m.url"
                v-html="m.name"
                class="f-black pointer"
                v-if="$route.path != m.url && m.url.slice(0, 1) != '#'"
              />
              <div
                v-scroll-to="m.url"
                v-html="m.name"
                class="f-black pointer"
                v-if="
                  $route.path != m.url &&
                  m.url.slice(0, 1) == '#' &&
                  hash != m.url &&
                  m.url != '/'
                "
              />
              <div
                @click="top"
                v-html="m.name"
                class="f-black pointer"
                v-if="$route.path == '/' && m.url == '/' && hash"
              />
              <span
                class="f-orange fuchi pointer"
                v-html="m.name"
                @click="top"
                v-if="
                  ($route.path == m.url && $route.path != '/') ||
                  ($route.path == '/' && hash == m.url) ||
                  ($route.path == '/' && m.url == '/' && !hash)
                "
              />
            </v-col>
          </v-row>

          <table
            class="relative-center inline-block mt-10 btn_tb event"
            :class="{ 'fade-in': btnShow, 'fade-out': !btnShow }"
          >
            <tr>
              <td class="px-4">
                <v-btn
                  block
                  outlined
                  color="#EF6C00"
                  class="f-10-xxs box-shadow bg-white"
                  @click="hideAppBar('sale')"
                  >売却</v-btn
                >
              </td>
              <td class="px-4">
                <v-btn
                  block
                  outlined
                  color="#EF6C00"
                  class="f-10-xxs box-shadow bg-white"
                  @click="hideAppBar('buy')"
                  >購入</v-btn
                >
              </td>
              <td class="px-4">
                <v-btn
                  block
                  outlined
                  color="#EF6C00"
                  class="f-10-xxs box-shadow bg-white"
                  @click="hideAppBar('manage')"
                  >管理</v-btn
                >
              </td>
              <td class="px-4">
                <v-btn
                  block
                  outlined
                  color="#EF6C00"
                  class="f-10-xxs box-shadow bg-white"
                  @click="hideAppBar('use')"
                  >活用</v-btn
                >
              </td>
              <td class="px-4">
                <v-btn
                  block
                  outlined
                  color="#EF6C00"
                  class="f-10-xxs box-shadow bg-white"
                  @click="hideAppBar('etc')"
                  >その他</v-btn
                >
              </td>
            </tr>
          </table>

          <!-- <router-link to="/estate/count">
            <div
              class="absolute inline-block bg-red f-white flex-center f-11 box-shadow disno-menu buken_btn rounded event"
              @click="hideAppBar"
            >
              <div class="float-left pl-1 flex-center">
                <v-icon class="f-white mr-3 f-22 relative t-12"
                  >fas fa-home</v-icon
                >
              </div>
              <div class="float-left">
                <span class="f-yellow f-09">毎日更新</span><br />
                物件のご案内
              </div>
              <div class="float-left pl-6 f-10 relative">
                <span>掲載数</span
                ><span class="f-22 noto9 f-yellow pl-2">{{
                  count.toLocaleString()
                }}</span
                >&nbsp;<span class="f-yellow">件</span>
              </div>
              <div class="clear" />
            </div>
          </router-link>

          <router-link to="/register" v-if="!login">
            <div
              class="absolute inline-block f-white flex-center f-11 box-shadow disno-menu register_btn rounded event"
              @click="hideAppBar"
            >
              <div>
                会員限定<br />
                [非公開]<span class="f-09">&nbsp;物件を見る</span>
              </div>
            </div>
          </router-link> -->

          <!-- <div class="event absolute disno-menu t-12 r-20" v-else>
            <a href="/logout" class=" ">
              <div>ログアウト</div>
            </a>
            <div class="mt-4 f-red pointer" @click="deleteAcount">登録解除</div>
          </div> -->
        </div>
        <!-- スマホ -->
        <div
          class="float-left f-10-xxs f-orange pt-1 bold dis-menu event"
          :class="{ 'fade-in': btnShow, 'fade-out': !btnShow }"
        >
          <div>
            <span class="px-2" @click="hideAppBar('sale')">売却 </span>
            <span class="px-2" @click="hideAppBar('buy')">購入 </span>
            <span class="px-2" @click="hideAppBar('manage')">管理 </span>
          </div>
          <div class="pt-2">
            <span class="px-2" @click="hideAppBar('use')">活用 </span>
            <span class="px-2" @click="hideAppBar('etc')">その他 </span>
          </div>
        </div>

        <div class="float-right dis-menu event">
          <div class="inline-block text-center" @click="drawer = true">
            <v-icon class="f-30">mdi-menu </v-icon>
            <div class="f-10 relative t--5">MENU</div>
          </div>
        </div>
        <div class="clear" />
      </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary right>
      <div class="text-center">
        <!-- <router-link to="/estate/count">
          <div class="w-100 bg-red f-white f-11 py-4">
            <div class="inline-block relative r-8">
              <div class="float-left flex-center">
                <v-icon class="f-white mr-3 f-22 relative t-12"
                  >fas fa-home</v-icon
                >
              </div>
              <div class="float-left">
                <span class="f-yellow f-09">毎日更新</span><br />
                物件のご案内
              </div>
              <div class="clear" />
            </div>
            <div class="f-10 relative">
              <span>掲載数</span
              ><span class="f-22 noto9 f-yellow pl-2">{{
                count.toLocaleString()
              }}</span
              >&nbsp;<span class="f-yellow">件</span>
            </div>
          </div>
        </router-link> -->
        <!-- <router-link to="/register" v-if="!login">
          <div
            class="w-100 f-white flex-center f-11 register_btn event"
            @click="hideAppBar"
          >
            <div>
              会員限定<br />
              [非公開]<span class="f-09">&nbsp;物件を見る</span>
            </div>
          </div>
        </router-link> -->
        <div
          v-for="m in $route.path == '/' ? menus : menus2"
          :key="m.url"
          class="f-12 bold my-4"
          @click="drawer = false"
        >
          <router-link
            :to="m.url"
            v-html="m.name"
            class="f-black"
            v-if="$route.path != m.url && m.url.slice(0, 1) != '#'"
          />
          <div
            v-scroll-to="m.url"
            v-html="m.name"
            class="f-black pointer"
            v-if="$route.path != m.url && m.url.slice(0, 1) == '#'"
          />
          <span
            class="f-orange fuchi pointer"
            v-html="m.name"
            @click="top"
            v-if="$route.path == m.url"
          />
        </div>
        <div class="event mt-16" v-if="login">
            <a href="/logout" class=" ">
              <div>ログアウト</div>
            </a>
            <div class="mt-6 f-red pointer" @click="deleteAcount">登録解除</div>
          </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Menu from "@/mixins/menu2";
import Scroll from "@/mixins/scroll";
import axios from "axios";
import d from "@/d";

export default {
  mixins: [Menu, Scroll],
  data() {
    return {
      drawer: false,
      hash: null,
      color: "rgba(255,255,255,0)",
      count: 0,
      btnShow: 0,
      login: null,
    };
  },
  async mounted() {
    if (window.innerWidth > 1449) {
      this.color = "rgba(255,255,255,0.8)";
    }
    const response = await axios.post("/estate/count/all");
    this.count = response.data.count;
    this.login = response.data.login;
  },
  methods: {
    top() {
      window.scroll({ top: 0, behavior: "smooth" });
      this.drawer = false;
    },
    async scroll01() {
      if (
        window.innerHeight + this.scroY >
          document.getElementById("company").getBoundingClientRect().top * 3 &&
        window.innerHeight + this.scroY <
          document.getElementById("order").getBoundingClientRect().top * 6
      ) {
        this.hash = "#company";
      } else if (
        window.innerHeight + this.scroY >=
        document.getElementById("order").getBoundingClientRect().top * 6
      ) {
        this.hash = "#order";
      } else {
        this.hash = null;
      }
    },

    async jamp(val) {
      if (val) {
        this.$router.push({
          name: "realEstate",
          hash: "#" + val,
        });
        await d.sleep(1000);
        this.up = 0;
      }
    },
    async hideAppBar(val) {
      await this.jamp(val);
      this.$refs.appBar.isActive = false;
    },
    showAppBar() {
      this.$refs.appBar.isActive = true;

      if (window.innerWidth > 1449) {
        return false;
      }
      if (this.scroY > 500) {
        this.color = "rgba(255,255,255,0.8)";
      } else {
        this.color = "rgba(255,255,255,0)";
      }
    },
    async btnIf() {
      if (this.$route.name == "realEstate" && this.scroY > 500) {
        this.btnShow = 1;
      } else {
        this.btnShow = 0;
      }
    },

    async deleteAcount() {
      if (window.confirm("解除してよろしいですか？")) {
        const response = await axios.post("/user/delete");

        if (response.data.ok) {
          window.location.href = "/estate/count#delete";
        } else {
          console.log(response.data);
        }
      }
    },
  },
  watch: {
    scroY(befor, after) {
      if (befor > after) {
        this.hideAppBar();
      } else {
        this.showAppBar();
      }
      if (this.$route.path == "/") {
        this.scroll01();
      }
      this.btnIf();
    },
  },
};
</script>

<style scoped>
.v-app {
  height: 86px !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/sass/components/header2.scss";
</style>
